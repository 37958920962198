import "./Footer.scss";
import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ViewAllCareersButton } from "../svg/viewAllCareersButton.svg";
import { ReactComponent as FacebookIcon } from "../svg/icon-facebook.svg";
import { ReactComponent as InstagramIcon } from "../svg/icon-instagram.svg";
import { ReactComponent as TwitterIcon } from "../svg/icon-twitter.svg";
import { ReactComponent as LinkedInIcon } from "../svg/icon-linkedin.svg";
import { ReactComponent as YouTubeIcon } from "../svg/icon-youtube.svg";

function Footer() {
  return (
    <Row lg={12} className="footer">
      <Col md={12} lg={12}>
        <Row className="hd-ftr" xs={12} md={12} lg={12}>
          <Col xs={12} md={4} lg={4} className="d-none d-lg-block d-xl-none d-xl-block">
            <a href="https://arizona.byf.org/" target="blank" rel="noopener noreferrer"><img src="../images/logo-byfaz-gray.png" /></a>
          </Col>
          <Col xs={12} md={12} lg={4} className="align-middle align-center">
            <span className="results-ftr-md-wh">GET STARTED</span>
            <div className="ftr-menu">
              <a href="https://arizona.byf.org/" target="_blank">BYFAZ</a>
              <a href="https://careers.byfaz.org/" target="_blank">Careers</a>
              <a href="https://arizona.byf.org/investors/" target="_blank">Investors</a>
            </div>
            <span className="results-ftr-wh">FOLLOW US</span>
              <Row xs={4} md={4} lg={4} className="social-icons justify-content-center">
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.facebook.com/BYFArizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><FacebookIcon/> </a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.instagram.com/byfarizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><InstagramIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://twitter.com/byfarizona" target="_blank" rel="noopener noreferrer" className="align-middle"><TwitterIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.linkedin.com/company/byfarizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><LinkedInIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.youtube.com/channel/UC1qfB2ovTOUjBI3V_XRR7yg" target="_blank" rel="noopener noreferrer" ><YouTubeIcon/></a>
                    </Col>
                    <Col xs={12} md={12} lg={12} className="d-none d-sm-block d-md-none d-block d-sm-none d-md-block d-lg-none">
                      <span className="results-ftr-md-sm-wh">
                        Build Your Future Arizona is a project of the <a href="https://phoenixchamberfoundation.com/workforce-development/construction/" target="_blank" rel="noopener noreferrer">Phoenix Chamber Foundation</a>. All material © 2021 <a href="https://byf.org/" target="_blank" rel="noopener noreferrer">BYF</a>.
                      </span>
                    </Col>
                  </Row>
            <Row>
            
              <Col xs={12} md={12} lg={4} className="d-none d-sm-block d-md-none d-block d-sm-none d-md-block d-lg-none byf-logo">
                <a href="https://arizona.byf.org/" target="blank" rel="noopener noreferrer"><img src="../images/logo-byfaz-gray.png" /></a>
              </Col>
              <Col xs={12} md={12} lg={4} className="align-items-center d-none d-sm-block d-md-none d-block d-sm-none d-md-block d-lg-none gpc-logo">
                <a href="https://phoenixchamberfoundation.com/" target="blank" rel="noopener noreferrer"><img src="../images/logo-gpcf.png" /></a>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={4} lg={4} className="align-items-center d-none d-lg-block d-xl-none d-xl-block">
            <a href="https://phoenixchamberfoundation.com/" target="blank" rel="noopener noreferrer"><img src="../images/logo-gpcf.png" /></a>
          </Col>
        </Row>
      </Col>
      <Col xs={12} md={12} lg={12} className="d-none d-lg-block d-xl-none d-xl-block">
        <span className="results-ftr-md-sm-wh">
          Build Your Future Arizona is a project of the <a href="https://phoenixchamberfoundation.com/workforce-development/construction/" target="_blank" rel="noopener noreferrer">Phoenix Chamber Foundation</a>. All material © 2021 <a href="https://byf.org/" target="_blank" rel="noopener noreferrer">BYF</a>.
        </span>
      </Col>
    </Row>
  );
}

export default Footer;
