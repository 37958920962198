import React, { useState, useEffect } from "react";
import "./Results.scss";
import Header from "../components/Header";
import CareerCard from "../components/CareerCard";
import SocialShare from "../components/SocialShare";
import ResultSlider from "../components/ResultSlider";
import Footer from "../components/Footer";
import axios from 'axios';

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as ViewAllCareersButton } from "../svg/viewAllCareersButton.svg";
import { ReactComponent as CareerCenterButton } from "../svg/findTrainingButton.svg";
import { ReactComponent as RetakeAssessmentButton } from "../svg/retakeAssessmentButton.svg";

import { useHistory } from "react-router-dom";

const Home = (props) => {

  const history = useHistory();

  let jobSelected = {};
  let jobFSelected = {};

  let jobsArray = [];
  let strTotalJobs = "";
  
  // Check Results Page Access
  const checkPageAccess = (history) => {
    const quesOneResults = JSON.parse(localStorage.getItem("question_one"));
    const quesTwoResults = JSON.parse(localStorage.getItem("question_two"));
    const quesThreeResults = JSON.parse(localStorage.getItem("question_three"));
    const quesFourResults = JSON.parse(localStorage.getItem("question_four"));
    
    if (
      quesOneResults == null ||
      quesTwoResults == null ||
      quesThreeResults == null ||
      quesFourResults == null
    ) {
      history.push("/entryform");
    } else {
      return true;
    }
  };

  const buttonHandler = () => {
    history.push("/entryform");
  };

  const convertStringToArrayResults = (jobArray) => {
    for (const [key, value] of Object.entries(jobArray)) {
      if (value) {
        strTotalJobs += value + ",";
      }
    }

    jobsArray = strTotalJobs.split(",");

    jobsArray.filter(function (e) {
      return e;
    });

    return jobsArray;
  };

 
  const publishResultsActiveCampaign = (jobs) => {
    
    const formResults = JSON.parse(localStorage.getItem("entryform"));
    
    const jobQuestionOne = JSON.parse(localStorage.getItem("question_one_selected"));
    const jobQuestionTwo = JSON.parse(localStorage.getItem("question_two_selected"));
    const jobQuestionThree = JSON.parse(localStorage.getItem("question_three_selected"));
    const jobQuestionFour = JSON.parse(localStorage.getItem("question_four_selected"));
    
    const user = {
      id: formResults.id,
      email: formResults.email,
      jobChoiceOne:jobs[0],
      jobChoiceTwo:jobs[1],
      jobChoiceThree:jobs[2],
      jobChoiceFour:jobs[3],
      jobQuestionOne:jobQuestionOne,
      jobQuestionTwo:jobQuestionTwo,
      jobQuestionThree:jobQuestionThree,
      jobQuestionFour:jobQuestionFour
    }
    
     axios.post(`/api/updateaccount`, { user })
     .then(res => {
       
     });
    
  }

  const buildJobResults = () => {
    const quesOneResults = JSON.parse(localStorage.getItem("question_one"));
    const quesTwoResults = JSON.parse(localStorage.getItem("question_two"));
    const quesThreeResults = JSON.parse(localStorage.getItem("question_three"));
    
    const countOccurrences = (arr, val) =>
      arr.reduce((a, v) => (v === val ? a + 1 : a), 0);

    let quesOneArray = [];
    let quesTwoArray = [];
    let quesThreeArray = [];

    quesOneArray = convertStringToArrayResults(quesOneResults);
    quesTwoArray = convertStringToArrayResults(quesTwoResults);
    quesThreeArray = convertStringToArrayResults(quesThreeResults);

    let fResultsArray = [];

    fResultsArray = [].concat(quesOneArray, quesTwoArray, quesThreeArray);

    let resultsArray = [];
    let occurrenceCount = 0;

    for (let i = 0; i < fResultsArray.length; i++) {
      occurrenceCount = countOccurrences(fResultsArray, fResultsArray[i]);
      if(fResultsArray[i]) {
        resultsArray.push({ job: fResultsArray[i], count: occurrenceCount });
      }
    }

    var results = resultsArray.reduce((unique, o) => {
      if (!unique.some((obj) => obj.job === o.job && obj.count === o.count)) {
        unique.push(o);
      }
      return unique;
    }, []);

    results.sort((a, b) => (a.count < b.count ? 1 : -1));

    return results;
  };
  
  // Validate user has completed questions
  let pageAccess = false;

  pageAccess = checkPageAccess(history);

  let jobArrayResults = [];
  let jobsFitLikeAGlove = [];
  let jobsNextBestOption = [];
  let jobsAdditionalCarreer = [];

  if (pageAccess) {
    jobArrayResults = buildJobResults();
    
    jobsFitLikeAGlove = jobArrayResults.slice(0, 4);
    jobsNextBestOption = jobArrayResults.slice(4, 8);
    jobsAdditionalCarreer = jobArrayResults.slice(8, 12);
    
    console.log(jobsNextBestOption);
    

  }
  
  useEffect(() => {
      jobsFitLikeAGlove = jobArrayResults.slice(0, 4);
      // Publish Results to ActiveCampaign
      publishResultsActiveCampaign(jobsFitLikeAGlove);
    
  },[]);
  
  return (
    <div className="results-page">
      <Header />
      <Row lg={12} className="justify-content-center bg-blk">
        <Col xs={12} md={12} lg={12}>
          <span className="results-hd-sm-top">RESULTS</span>
          <hr className="red-bar"></hr>
        </Col>
        <Col xs={12} md={12} lg={12}>
          <span className="results-hd-lg-wh">
            CRAFT YOUR OPPORTUNITY.
          </span>
          <p>Congrats on completing the assessment! Your answers help determine what jobs are best for you.</p>
          <p>
            But please note: the Career Match is not the end all be all! You may find other careers worth exploring. In fact,
            we hope you do.
          </p>
          <hr className="grey-bar"></hr>
        </Col>
        <Col xs={12} md={12} lg={12} className="mh-100">
          <span className="results-hd-sm-cp">CAREER PATHS TO CONSIDER</span>
          <img src="../images/icon-arrow-down.png" className="arrow-down"></img>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-wh">
        <Col xs={12} md={12} lg={12} className="mh-100">
          <span className="results-hd-md-bl">
            <br />
            CAREER PATHS THAT FIT LIKE A GLOVE
          </span>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-wh">
        {jobsFitLikeAGlove.map((x) => (
          <CareerCard position={x.job} />
        ))}
      </Row>
      <Row lg={12} className="justify-content-center bg-wh">
        <Col xs={12} md={12} lg={12} className="mh-100">
          <br />
          <br />
          <br />
          <span className="results-hd-md-bl">YOUR NEXT BEST OPTION</span>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-wh">
        {jobsNextBestOption.map((x) => (
          <CareerCard position={x.job} />
        ))}
      </Row>

      <Row lg={12} className="justify-content-center bg-wh">
        <Col xs={12} md={12} lg={12} className="mh-100">
          <br />
          <span className="results-hd-md-bl">Interested in more?</span>
          <a href="https://arizona.byf.org/explore-careers/trading-cards/" target="blank" rel="noopener noreferrer"><ViewAllCareersButton /></a>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center slider-bg">
        <Col xs={12} md={12} lg={12}>
          <br />
          <br />
          <br />
          <ResultSlider />
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-blk">
        <Col xs={12} md={12} lg={12}>
          <span className="results-hd-md-wh next-step">
            READY TO TAKE THE NEXT STEP?
          </span>
          <a href="https://arizona.byf.org/find-training/" target="blank" rel="noopener noreferrer"><CareerCenterButton /></a>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-blk">
        <Col xs={12} md={12} lg={12}>
          <hr className="grey-bar"></hr>
        </Col>
      </Row>
      <Row lg={12} className="justify-content-center bg-blk">
        <Col xs={12} md={12} lg={12}>
          <span className="results-hd-md-wh">Explore another career path?</span>
          <a href="/question-one"><RetakeAssessmentButton /></a>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default Home;
