import "./Home.scss";
import Header from "../components/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ReactButton } from "../svg/takeAssessmentButton.svg";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

function Home(props) {
  
  const [enteredFirstName, setFirstName] = useState("");
  const [enteredLastName, setLastName] = useState("");
  const [enteredEmail, setEmail] = useState("");
  const [enteredPhone, setPhone] = useState("");
  const [enteredFullName, setFullName] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = window.innerWidth >= 990 ? "../images/image_hero_1.png" : "../images/mobile_hero_1.png";
  
  const isMobile = useMediaQuery({ query: '(min-width: 200px) and (max-width: 767.98px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 767.00px) and (max-width: 991.98px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });
  
  
  if(isDesktop) {
     var dkBackgroundImage = {
      backgroundImage: 'url(' + imageUrl + ')'
    };
  } else if(isMobile) {
     var mbBackgroundImage = {
      backgroundImage: 'url(' + imageUrl + ')'
    };
  }

  const getActiveCampaigninfo = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userid = urlParams.get('id');
     
    const user = {
      email: userid
    };

    if(userid) {
      axios.post(`/api/getaccountinfo`, { user })
        .then(res => {
          if(res.data.response.email) {
            setFirstName(res.data.response.first_name);
            setLastName(res.data.response.last_name);
            setEmail(res.data.response.email);
            setPhone(res.data.response.phone);
          
            // Set Hello Name on header component for user cofirmation of account
            setFullName(res.data.response.first_name + " " + res.data.response.last_name);
            
            let user = {
                  firstname: res.data.response.first_name,
                  lastname: res.data.response.last_name,
                  email: res.data.response.email,
                  phone: res.data.response.phone
            }
            
            // Store data in localstorage
            localStorage.setItem("entryform", JSON.stringify(user));
          }
      })
    }
  }
  
  useEffect(() => {
    
    getActiveCampaigninfo();
    
    // const handleWindowResize = () => {
    //   setWindowWidth(window.innerWidth);
    // };
        
    // window.addEventListener('resize', handleWindowResize);

    // return () => {
    //   window.removeEventListener('resize', handleWindowResize);
    // }
    
  },[]);
  
  const history = useHistory();
  const buttonHandler = () => {
    if(enteredEmail.length > 0) {
      history.push("/entryform?id=" + enteredEmail);
    } else {
      history.push("/entryform");
    } 
  };

  return (
    <div
      className="home-page h-100 bg-color bg-image" style={dkBackgroundImage}>
      <Header fullname={enteredFullName} />
      <Row xs={12} md={12} lg={12} className="justify-content-center bg-image" style={mbBackgroundImage}>
        <Col xs={12} md={12} lg={5}>
          <img
            src="../images/logo-career-match.png"
            width="100%"
            alt=""
            className="career-match"
          />
          <p>
           Wondering which careers in the skilled crafts best fit
          you? This four-question quiz is designed to reveal your
          strengths and interests--and help you identify your
          path to a brighter future in construction.
          </p>
          <ReactButton onClick={buttonHandler} />
          <p>Already have an idea of the kind of roles you’re looking for?</p>
          <p><a href="https://arizona.byf.org/explore-careers/trading-cards/" rel="noopener noreferrer" target="_blank" className="careers">See All Careers</a></p>
        </Col>
        <Col xs={12} md={12} lg={7}></Col>
      </Row>
       <Row lg={12} className="justify-content-center">
        <Col xs={12} md={12} lg={12}><br/><br/></Col>
      </Row>
    </div>
  );
}

export default Home;
