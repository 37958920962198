import "./QuestionTwo.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ReactButton } from "../svg/nextQuestionButton.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useMediaQuery } from 'react-responsive';

function QuestionTwo() {
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  
  const isMobile = useMediaQuery({ query: '(min-width: 200px) and (max-width: 767.98px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 767.00px) and (max-width: 991.98px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  // Form Data States
  const [enteredResp2, setResp2] = useState("");
  const [enteredQues2, setQues2] = useState("");

  const selectedA = [
    "Construction Manager",
    "Framer",
    "Underground Construction",
    "Mason",
    "Electrician",
    "Plumber",
    "HVAC Tech",
    "Spinkler Fitter",
    "Concrete Finisher",
    "Painter",
  ];
  const selectedB = [
    "Construction Manager",
    "Ironworker",
    "Sprinkler Fitter",
    "Sheet Metal Worker",
    "Pipefitter",
    "Plumber",
    "Welder",
    "HVAC Tech",
    "Mason",
    "Concrete Finisher",
    "Painter",
    "Underground Construction",
    "Roofer",
    "Framer",
    "Electrician",
    "Carpenter",
    "Craft Laborer",
    "Drywall Installer",
    "Millwright",
  ];
  const selectedC = [
    "Roofer",
    "Framer",
    "Electrician",
    "Carpenter",
    "Craft Laborer",
    "Mason",
    "Drywall Installer",
    "Millwright",
    "Plumber",
    "Pipefitter",
    "Sheet Metal Worker",
    "Welding",
    "Sprinkler Fitter",
    "Concrete Finisher",
    "Heavy Equipment Operator",
    "Painter",
    "Underground Construction",
  ];
  const selectedD = [
    "Roofer",
    "Framer",
    "Electrician",
    "Carpenter",
    "Craft Laborer",
    "Mason",
    "Drywall Installer",
    "Millwright",
    "Plumber",
    "Pipefitter",
    "Sheet Metal Worker",
    "Welding",
    "Construction Manager",
    "Iron Worker",
    "Sprinkler Fitter",
    "Concrete Finisher",
  ];

  const quesJobs = {
    selectedA,
    selectedB,
    selectedC,
    selectedD,
  };

  const resp2ChangeEventHander = (event) => {
    const questionTwo = "If you could do any of the following, which would you choose?";
    setResp2(event.target.value);
    setQues2(questionTwo + ":" + event.target.nextSibling.innerHTML);
  };
  
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      console.log(form);
    }

    if (form.checkValidity() === true) {
      const formData = {
        response2: enteredResp2,
      };
      localStorage.setItem("question_two", JSON.stringify(formData));
      localStorage.setItem("question_two_selected", JSON.stringify(enteredQues2));
      history.push("/question-three");
    }
  };
  return (
    <div
      className="question-two container-fluid h-100 assessment-two__background-image">
      <Row lg={12} className="h-100 justify-content-center align-items-center">
      {isDesktop &&
       <video autoPlay loop muted id="video" className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
            <source src="../video/Background_2.mp4" type="video/mp4" />
      </video>
      }
        <Col xs={12} md={12} lg={12} className="mh-100 bg-img-home">
          <img
            src="../images/logo-byfaz.png"
            alt=""
            className="assessment-two__logo"
          />
          <span className="assessment-two__question-count">
            Question 2 of 4
          </span>
          <div className="pb-wrapper">
            <div className="progressbar">
              <div className="redbar"></div>
              <div className="redbar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
          <span className="form-title">
            If you could Do any of the following,
            <br />
            which would you choose?
          </span>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              {["radio"].map((type) => (
                <div key={type} className="mb-3">
                  <Row>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response2"
                          type={type}
                          onChange={resp2ChangeEventHander}
                          value={selectedA}
                          className="fancy"
                          required
                        />
                        <div>Lead a team</div></Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response2"
                          type={type}
                          onChange={resp2ChangeEventHander}
                          value={selectedB}
                          className="fancy"
                        />
                        
                          <div>Follow instruction to assemble furniture</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response2"
                          type={type}
                          onChange={resp2ChangeEventHander}
                          value={selectedC}
                          className="fancy"
                        />
                        <div>Plan a trip</div></Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response2"
                          type={type}
                          onChange={resp2ChangeEventHander}
                          value={selectedD}
                          className="fancy"
                        />
                        
                          <div>Repair and restore a vehicle</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button type="submit">
                <ReactButton></ReactButton>
              </Button>
        
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default QuestionTwo;
