import "./SocialShare.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";

function SocialShare() {
  const history = useHistory();

  return (
    <div
      className="social-share container-fluid h-100 assessment-two__background-image"
      style={{ backgroundImage: "url(../svg/bigTruckBackground.svg)" }}
    >
      <Row lg={12} className="justify-content-center align-items-center">
        <Col xs={12} md={12} lg={12} className="bg-img-home"></Col>
        <img src="../images/logo-career-match.png"></img>
        <p>
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua.
        </p>
      </Row>
    </div>
  );
}

export default SocialShare;
