import "./EntryForm.scss";
import Header from "../components/Header";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ReactButton } from "../svg/takeAssessmentButton.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useHistory } from "react-router-dom";

function EntryForm(props) {
  const [validated, setValidated] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const imageUrl = window.innerWidth >= 990 ? "../images/image_hero_entry_form_tablet.png" : "../images/mobile_hero_1.png";

  // Form Data States
  const [enteredFirstName, setFirstName] = useState("");
  const [enteredLastName, setLastName] = useState("");
  const [enteredEmail, setEmail] = useState("");
  const [enteredPhone, setPhone] = useState("");
  const [enteredTerms, setTerms] = useState("");
  const [enteredTextUpdates, setTextUpdates] = useState("");
  const [enteredFullName, setFullName] = useState("");

  const firstNameChangeEventHander = (event) => {
    setFirstName(event.target.value);
  };

  const lastNameChangeEventHander = (event) => {
    setLastName(event.target.value);
  };

  const emailChangeEventHander = (event) => {
    setEmail(event.target.value);
  };

  const phoneChangeEventHander = (event) => {
    setPhone(event.target.value);
  };

  const termsChangeEventHander = (event) => {
    setTerms(event.target.value);
    console.log(enteredTerms);
  };
  
  const textUpdatesChangeEventHander = (event) => {
    setTextUpdates(event.target.value);
    console.log(enteredTerms);
  };
  
  const createActiveCampaignUser = (user) => {
    
     axios.post(`/api/addaccount`, { user })
        .then(res => {
            let activeCampaignStatus = res.data.response.result_code;

            console.log(res);

            if(activeCampaignStatus == 0) {
               user.status = "update";
               user.id = res.data.response[0].id;
            }
            
            if(activeCampaignStatus == 1) {
              user.status = "new";
              user.id = res.data.response.subscriber_id;
            }
            
            localStorage.setItem("entryform", JSON.stringify(user));
            history.push("/question-one");
        });
  } 
  
  const getActiveCampaigninfo = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userid = urlParams.get('id');
     
    const user = {
      email: userid
    };
    
    if(userid) {
      axios.post(`/api/getaccountinfo`, { user })
          .then(res => {
              if(res.data.response.email) {
                
                let user = {
                  id: res.data.response.id,
                  firstname: res.data.response.first_name,
                  lastname: res.data.response.last_name,
                  email: res.data.response.email,
                  phone: res.data.response.phone,
                  status: "update"
                }
                
                localStorage.setItem("entryform", JSON.stringify(user));
                history.push("/question-one");
              }
      });
    }
  }
  
  useEffect(() => {
    
    getActiveCampaigninfo();
    
    const handleWindowResize = () => {
            setWindowWidth(window.innerWidth);
        };
        
        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        }
  },[]);

  const history = useHistory();

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }

    if (form.checkValidity() === true) {
      
      const formData = {
        id:"",
        firstname: enteredFirstName,
        lastname: enteredLastName,
        email: enteredEmail,
        phone: enteredPhone,
        terms: enteredTerms,
        status: "new"
      };
      
      createActiveCampaignUser(formData);
    }
  };

  return (
    <div className="entry-form h-100">
      <Header fullname={enteredFullName} />
      <Row lg={12} className="justify-content-center bg-form">
        <Col
          xs={12}
          md={12}
          lg={6}
          className="assessment-bg h-100"
          style={{ backgroundImage: `url(${imageUrl})` }}
        >
          <img
            src="../images/logo-career-match.png"
            width="100%"
            alt=""
            className="career-match"
          />
          <p>
             Wondering which careers in the skilled crafts best fit
          you? This four-question quiz is designed to reveal your
          strengths and interests--and help you identify your
          path to a brighter future in construction.
          </p>
        </Col>
        <Col
          xs={12}
          md={12}
          lg={6}
          className="bg-img-home align-self-center"
        >
          <span className="form-title">FILL OUT FORM TO ACCESS YOUR MATCH</span>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Control
                size="lg"
                required
                type="text"
                onChange={firstNameChangeEventHander}
                id="firstname"
                value={enteredFirstName}
                placeholder="First Name"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a First Name.
              </Form.Control.Feedback>
              <br />
              <Form.Control
                size="lg"
                required
                type="text"
                id="lastname"
                placeholder="Last Name"
                value={enteredLastName}
                onChange={lastNameChangeEventHander}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a Last Name.
              </Form.Control.Feedback>
              <br />
              <Form.Control
                size="lg"
                required
                type="email"
                id="email"
                placeholder="Email"
                value={enteredEmail}
                onChange={emailChangeEventHander}
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid email address.
              </Form.Control.Feedback>
              <br />
              <Form.Control
                size="lg"
                required
                type="phone"
                id="phone"
                placeholder="Phone"
                value={enteredPhone}
                onChange={phoneChangeEventHander}
              />
              <Form.Check
                required
                name="terms"
                label="By submitting this form, you are confirming you agree to share your personal information with Build Your Future Arizona (BYFAZ) and to receive future communications from BYFAZ. You may unsubscribe at any time."
                onChange={termsChangeEventHander}
                value={enteredTerms}
              />
              <Form.Check
                name="textupdates"
                label="By checking this box, you are confirming that you agree to receive text updates from Build Your Future Arizona (BYFAZ). You may unsubscribe at any point by responding STOP"
                onChange={textUpdatesChangeEventHander}
                value={enteredTextUpdates}
              />
              <Button type="submit">
                <ReactButton></ReactButton>
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default EntryForm;
