import "./ResultSlider.scss";

import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";


const ResultSlider = (props) => {
  
     // Build out slide menu on reponse from question 4 
  const buildSlideOut = () => {
      
      const quesFourResults = JSON.parse(localStorage.getItem("question_four"));
      
      let quesFourResponse = quesFourResults.response4;
      
      const slideOutImages = {
        "Leadership":{
          "slide1":{
            "desktop":"../images/slides/image-feature-1_desktop.jpg",
            "tablet":"../images/slides/image-feature-1_tablet.jpg",
            "mobile":"../images/slides/image-feature-1_mobile.jpg",
            "caption":"Climb the ladder.",
            "text":"No matter where you start, if you work hard and set some goals, you can move up the ranks and take on more responsibility as you go."
          },
          "slide2":{
            "desktop":"../images/slides/image-feature-2_desktop.jpg",
            "tablet":"../images/slides/image-feature-2_tablet.jpg",
            "mobile":"../images/slides/image-feature-2_mobile.jpg",
            "caption":"Find a mentor.",
            "text":"A good way to prime yourself for a leadership role in the industry involves identifying a mentor—someone you can learn from and model yourself after."
        
          },
          "slide3":{
            "desktop":"../images/slides/image-feature-3_desktop.jpg",
            "tablet":"../images/slides/image-feature-3_tablet.jpg",
            "mobile":"../images/slides/image-feature-3_mobile.jpg",
            "caption":"Transfer Your skills.",
            "text":"No matter where you start, if you work hard and set some goals, you can move up the ranks and take on more responsibility as you go. "
        
          },
          "slide4":{
            "desktop":"../images/slides/image-feature-4_desktop.jpg",
            "tablet":"../images/slides/image-feature-4_tablet.jpg",
            "mobile":"../images/slides/image-feature-4_mobile.jpg",
            "caption":"SPEAK UP",
            "text":"A career in construction is sure to leave a lasting impact on the community that we live in. From residential areas to hospital buildings, the work you do will leave a legacy for future generations."
          }
        },
      "Teamwork":{
          "slide1":{
            "desktop":"../images/slides/image-feature-5_desktop.jpg",
            "tablet":"../images/slides/image-feature-5_tablet.jpg",
            "mobile":"../images/slides/image-feature-5_mobile.jpg",
            "caption":"Thrive in teamwork.",
            "text":"There’s no getting around the fact that the construction industry relies on teamwork in every way. If you can master the art of working with others and keeping a positive attitude, you’ll go far. "
          },
          "slide2":{
            "desktop":"../images/slides/image-feature-6_desktop.jpg",
            "tablet":"../images/slides/image-feature-6_tablet.jpg",
            "mobile":"../images/slides/image-feature-6_mobile.jpg",
            "caption":"Look out for one another.",
            "text":"On a job site, working as a team keeps you safe. Stay alert, follow safety protocols and help each other out."
          },
          "slide3":{
            "desktop":"../images/slides/image-feature-7_desktop.jpg",
            "tablet":"../images/slides/image-feature-7_tablet.jpg",
            "mobile":"../images/slides/image-feature-7_mobile.jpg",
            "caption":"Build Your Community.",
            "text":"If you can identify others around you who share your work ethic and interest in learning, you will help each other grow and progress. Share tips and techniques for doing better work and finishing on time. "
        
          },
          "slide4":{
            "desktop":"../images/slides/image-feature-8_desktop.jpg",
            "tablet":"../images/slides/image-feature-8_tablet.jpg",
            "mobile":"../images/slides/image-feature-8_mobile.jpg",
            "caption":"Lend a hand.",
            "text":"When you see a team member struggling with a task or in need of an extra pair of hands, be that support. Building trust on a team builds better productivity and outcomes."
          }
        },
      "Problem Solving Skills":{
          "slide1":{
            "desktop":"../images/slides/image-feature-9_desktop.jpg",
            "tablet":"../images/slides/image-feature-9_tablet.jpg",
            "mobile":"../images/slides/image-feature-9_mobile.jpg",
            "caption":"Take initiative.",
            "text":"Every supervisor appreciates an employee who takes a little ownership and tries to troubleshoot solutions to a problem on his or her own."
          },
          "slide2":{
            "desktop":"../images/slides/image-feature-10_desktop.jpg",
            "tablet":"../images/slides/image-feature-10_tablet.jpg",
            "mobile":"../images/slides/image-feature-10_mobile.jpg",
            "caption":"Show higher-level thinking.",
            "text":"If you can distinguish yourself by identifying and even better—solving a problem, you’ll be a standout on the job site. Take that extra step to be invested in the job."
          },
          "slide3":{
            "desktop":"../images/slides/image-feature-11_desktop.jpg",
            "tablet":"../images/slides/image-feature-11_tablet.jpg",
            "mobile":"../images/slides/image-feature-11_mobile.jpg",
            "caption":"See the big picture.",
            "text":"Not everyone has the vision to see beyond the task in front of them. If you can take a wider view of a situation, you’ll set yourself apart."
        
          },
          "slide4":{
            "desktop":"../images/slides/image-feature-12_desktop.jpg",
            "tablet":"../images/slides/image-feature-12_tablet.jpg",
            "mobile":"../images/slides/image-feature-12_mobile.jpg",
            "caption":"Always be open to learning new things.",
            "text":"One way to develop your problem-solving skills is to shadow someone who is a problem-solver, and more than anything else, stay open-minded and willing to learn.  "
          }
        },
      "Growth Potential":{
          "slide1":{
            "desktop":"../images/slides/image-feature-13_desktop.jpg",
            "tablet":"../images/slides/image-feature-13_tablet.jpg",
            "mobile":"../images/slides/image-feature-13_mobile.jpg",
            "caption":"Craft your opportunity.",
            "text":"When the industry has more work than workers, that’s an enormous opportunity for the next generation to learn more, faster. Take advantage of opportunities available to you."
          },
          "slide2":{
            "desktop":"../images/slides/image-feature-14_desktop.jpg",
            "tablet":"../images/slides/image-feature-14_tablet.jpg",
            "mobile":"../images/slides/image-feature-14_mobile.jpg",
            "caption":"Speak up!",
            "text":" If you want to move up the ranks, say so! Show some confidence and willingness to learn, and you just might find yourself with a promotion. Strike while the iron is hot!"
          },
          "slide3":{
            "desktop":"../images/slides/image-feature-15_desktop.jpg",
            "tablet":"../images/slides/image-feature-15_tablet.jpg",
            "mobile":"../images/slides/image-feature-15_mobile.jpg",
            "caption":"Make an impact.",
            "text":"A career in construction is sure to leave a lasting impact on the community that we live in. From residential areas to hospital buildings, the work you do will leave a legacy for future generations."
        
          },
          "slide4":{
            "desktop":"../images/slides/image-feature-16_desktop.jpg",
            "tablet":"../images/slides/image-feature-16_tablet.jpg",
            "mobile":"../images/slides/image-feature-16_mobile.jpg",
            "caption":"You’re primed for success.",
            "text":"With a looming staffing shortage, the most ambitious and determined workers can move quickly into more senior roles. It’s never too early to share your goals with a supervisor. Put yourself out there and let your company know you want more responsibility."
          }
        }
      }
      
      let selectSlides = [];
      
      for(const k in slideOutImages) {
        //console.log(k + ":" + slideOutImages[k]);
        if(quesFourResponse === k) {
          for(const l in slideOutImages[k]) {
            selectSlides.push(slideOutImages[k][l]);
          }
        }
      }
      
      return selectSlides;
  };
  
  let buildSlides = buildSlideOut();
  
    return (
        <Carousel>
          {buildSlides.map((item,index)=>{
            return (
            <Carousel.Item>
              <img
                className="d-none d-xl-block"
                src={item.desktop}
                alt="First slide"
              />
               <img
                className="d-none d-md-block d-lg-none"
                src={item.tablet}
                alt="First slide"
              />
               <img
                className="d-none d-sm-block d-md-none"
                src={item.mobile}
                alt="First slide"
              />
              <img
                className="d-block d-sm-none"
                src={item.mobile}
                alt="First slide"
              />
              
              <Carousel.Caption>
                <h3>{item.caption}</h3>
                <p>{item.text}</p>
              </Carousel.Caption>
            </Carousel.Item>
            )
            })}
          </Carousel>
    )
}


export default ResultSlider;