import React from "react";

import "./CareerCard.scss";
import Col from "react-bootstrap/Col";

const CareerCard = (props) => {

  const jobimages = {
    "Carpenter":{"image":"carpenter.png","link":"https://arizona.byf.org/blog/trading-card/carpenter/"},
    "Concrete Finisher":{"image":"concrete_finisher.png","link":"https://arizona.byf.org/blog/trading-card/concrete-finisher/"},
    "Cement Finisher":{"image":"concrete_finisher.png","link":"https://arizona.byf.org/blog/trading-card/concrete-finisher/"},
    "Craft Laborer":{"image":"craft_worker.png","link":"https://arizona.byf.org/blog/trading-card/craft-laborer/"},
    "Underground Construction":{"image":"underground_construction.png","link":"https://arizona.byf.org/blog/trading-card/underground-construction/"},
    "Painter":{"image":"painter.png","link":"https://arizona.byf.org/blog/trading-card/painter/"},
    "Sprinkler Fitter":{"image":"sprink_fit.png","link":"https://arizona.byf.org/blog/trading-card/sprinkler-fitter/"},
    "Construction Manager":{"image":"construction_manager.png","link":"https://arizona.byf.org/blog/trading-card/project-manager/"},
    "Sheet Metal Worker":{"image":"sheet_metal_worker.png","link":"https://arizona.byf.org/blog/trading-card/sheet-metal-worker/"},
    "Electrician":{"image":"electrician.png","link":"https://arizona.byf.org/blog/trading-card/electrician/"},
    "Mason":{"image":"mason.png","link":"https://arizona.byf.org/blog/trading-card/mason/"},
    "Framer":{"image":"framer.png","link":"https://arizona.byf.org/blog/trading-card/framer/"},
    "Plumber":{"image":"plumber.png","link":"https://arizona.byf.org/blog/trading-card/plumber/"},
    "Millwright":{"image":"millwright.png","link":"https://arizona.byf.org/blog/trading-card/millwright/"},
    "Drywall Installer":{"image":"drywall_installer.png","link":"https://arizona.byf.org/blog/trading-card/drywall-installer/"},
    "Roofer":{"image":"roofer.png","link":"https://arizona.byf.org/blog/trading-card/roofer/"},
    "Journeyman":{"image":"electrician.png","link":"https://arizona.byf.org/blog/trading-card/electrician/"},
    "Technician":{"image":"electrician.png","link":"https://arizona.byf.org/blog/trading-card/electrician/"},
    "Framer":{"image":"framer.png","link":"https://arizona.byf.org/blog/trading-card/framer/"},
    "Heavy Equipment Operator":{"image":"heavy_equipment_operator.png","link":"https://arizona.byf.org/blog/trading-card/heavy-equipment-operator/"},
    "HVAC Technician":{"image":"hvac_technician.png","link":"https://arizona.byf.org/blog/trading-card/hvac-technician/"},
    "Iron Worker":{"image":"ironworker.png","link":"https://arizona.byf.org/blog/trading-card/ironworker/"},
    "Masonry":{"image":"mason.png","link":"https://arizona.byf.org/blog/trading-card/mason/"},
    "Bricklayer":{"image":"mason.png","link":"https://arizona.byf.org/blog/trading-card/mason/"},
    "Pipefitter":{"image":"pipefitter.png","link":"https://arizona.byf.org/blog/trading-card/pipefitter/"},
    "Steel Worker":{"image":"sheet_metal_worker.png","link":"https://arizona.byf.org/blog/trading-card/sheet-metal-worker/"},
    "Welder":{"image":"welder.png","link":"https://arizona.byf.org/blog/trading-card/welder/"},
    "Fabricator,":{"image":"welder.png","link":"https://arizona.byf.org/blog/trading-card/welder/"},
    "Fabrication":{"image":"welder.png","link":"https://arizona.byf.org/blog/trading-card/welder/"}
  }
  
  let jobimage = "";
  let joblink = "";
  
  for(var key of Object.keys(jobimages)) {
    if(key == props.position) {
      jobimage = jobimages[key].image;
      joblink = jobimages[key].link;
    } 
  }
  
  let imageplace = "../images/" + jobimage;
  
  return (
    <Col xs={12} md={6} lg={3} className="mh-100 career-cards">
      <div className="career-card">
        <a href={joblink} target="_blank"><img src={imageplace} alt="" /></a>
        <span className="job-position">{props.position}</span>
        <a href={joblink} target="_blank" className="see-details">See Details ></a>
      </div>
    </Col>
  );
};

export default CareerCard;
