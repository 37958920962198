import "./Header.scss";
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as FacebookIcon } from "../svg/icon-facebook.svg";
import { ReactComponent as InstagramIcon } from "../svg/icon-instagram.svg";
import { ReactComponent as TwitterIcon } from "../svg/icon-twitter.svg";
import { ReactComponent as LinkedInIcon } from "../svg/icon-linkedin.svg";
import { ReactComponent as YouTubeIcon } from "../svg/icon-youtube.svg";

function Header(props) {
  
 function isVisible(e) {
    return !!( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
 }
 
  const menuClickHandler = () => {
    document.getElementById("menu").classList.toggle("active");
    document.getElementById("nav-menu").classList.toggle("active");
  };
  
  let fullName = props.fullname;

  return (
    <div className="main-header">
        <div id="nav-menu" className="nav-menu h-100 row-full">
              <ul>
                <li className="nav-item">
                  <a href="https://arizona.byf.org/" target="_blank" rel="noopener noreferrer" className="nav-link">
                    BYFAZ
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://careers.byfaz.org/" target="_blank" rel="noopener noreferrer" className="nav-link">
                    Career Center
                  </a>
                </li>
                <li className="nav-item">
                  <a href="https://es.arizona.byf.org/" target="_blank" rel="noopener noreferrer" className="nav-link">
                   BYFAZ en Espanol
                  </a>
                </li>
                <li className="nav-item">
                
                </li>
                <li className="nav-item">
                
                </li>
                <li className="nav-item">
                   <span className="follow-us">Follow Us</span>
                </li>
                <li>
                 <Row xs={4} md={4} lg={4} className="social-icons justify-content-center">
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.facebook.com/BYFArizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><FacebookIcon/> </a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.instagram.com/byfarizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><InstagramIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://twitter.com/byfarizona" target="_blank" rel="noopener noreferrer" className="align-middle"><TwitterIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.linkedin.com/company/byfarizona/" target="_blank" rel="noopener noreferrer" className="align-middle"><LinkedInIcon/></a>
                    </Col>
                    <Col xs={2} md={2} lg={2} className="d-flex flex-wrap align-items-center">
                      <a href="https://www.youtube.com/channel/UC1qfB2ovTOUjBI3V_XRR7yg" target="_blank" rel="noopener noreferrer" ><YouTubeIcon/></a>
                    </Col>
                  </Row>
                </li>
              </ul>
             
            </div>
      <nav className="navbar">
        <Row className="align-items-center">
          <Col xs={12} md={12} lg={12}>
        
          </Col>
        </Row>
      </nav>
      <Row className="header align-items-center">
        <Col xs={12} md={12} lg={12}>
          <div className="transparent-background"></div>
          <Row className="align-items-center">
          {!fullName &&
            <Col xs={6} md={6} lg={6}>
              <a href="/">
                <span className="align-middle">
                  <img src="../images/logo-byfaz.png" alt="" className="logo" />
                </span>
              </a>
            </Col>
          }
          {fullName &&
            <Col xs={3} md={2} lg={2}>
              <a href="/">
                <span className="align-middle">
                  <img src="../images/logo-byfaz.png" alt="" className="logo" />
                </span>
              </a>
            </Col>
          }
          {fullName &&
            <Col xs={3} md={4} lg={4}>
              <span className="greetuser align-middle">
                Hello, {fullName}
              </span>
              
            </Col>
            }
            <Col xs={6} md={6} lg={6}>
              <div id="menu" class="hamburger" onClick={menuClickHandler}>
                <span class="bar"></span>
                <span class="bar"></span>
                <span class="bar"></span>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Header;
