
function ContactUs() {
    
    return (
    <div className="career-center container-fluid">
        <div>Contact Us</div>
    </div>
    )
}

export default ContactUs;