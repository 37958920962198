
function CareerCenter() {
    
    return (
    <div className="career-center container-fluid">
        <div>Career Center</div>
    </div>
    )
}

export default CareerCenter;