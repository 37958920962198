import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import Home from "./pages/Home";
import CareerCenter from "./pages/CareerCenter";
import ContactUs from "./pages/ContactUs";
import EntryForm from "./pages/EntryForm";
import QuestionOne from "./pages/QuestionOne";
import QuestionTwo from "./pages/QuestionTwo";
import QuestionThree from "./pages/QuestionThree";
import QuestionFour from "./pages/QuestionFour";
import Results from "./pages/Results";
import SocialShare from "./pages/SocialShare";
import Container from "react-bootstrap/Container";
import ScrollToTop from "../src/components/ScrollToTop";

function App() {
  
  
  return (
    <Container fluid className="h-100">
      <ScrollToTop/>
       <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/career-center">
          <CareerCenter />
        </Route>
        <Route path="/contact-us">
          <ContactUs />
        </Route>
        <Route path="/entryform">
          <EntryForm />
        </Route>
        <Route path="/question-one">
          <QuestionOne />
        </Route>
        <Route path="/question-two">
          <QuestionTwo />
        </Route>
        <Route path="/question-three">
          <QuestionThree />
        </Route>
        <Route path="/question-four">
          <QuestionFour />
        </Route>
        <Route path="/results">
          <Results />
        </Route>
        <Route path="/social-share">
          <SocialShare />
        </Route>
      </Switch>
    </Container>
  );
}

export default App;
