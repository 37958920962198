import "./QuestionFour.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ReactButton } from "../svg/SeeResultsButton.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useMediaQuery } from 'react-responsive';

function QuestionFour() {
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  
  const isMobile = useMediaQuery({ query: '(min-width: 200px) and (max-width: 767.98px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 767.00px) and (max-width: 991.98px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  // Form Data States
  const [enteredResp4, setResp4] = useState("");
  const [enteredQues4, setQues4] = useState("");

  const resp4ChangeEventHander = (event) => {
    const questionFour = "What are you interested in learning more about?";
    setResp4(event.target.value);
    setQues4(questionFour + ":" + event.target.nextSibling.innerHTML);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
      console.log(form);
    }

    if (form.checkValidity() === true) {
      const formData = {
        response4: enteredResp4,
      };
      localStorage.setItem("question_four", JSON.stringify(formData));
      localStorage.setItem("question_four_selected", JSON.stringify(enteredQues4));
      history.push("/results");
    }
  };
  return (
    <div
      className="question-four container-fluid h-100 assessment-two__background-image">
      <Row lg={12} className="h-100 justify-content-center align-items-center">
       {isDesktop &&
          <video autoPlay loop muted id="video" className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
            <source src="../video/Background_4.mp4" type="video/mp4" />
          </video>
       }
        <Col xs={12} md={12} lg={12} className="mh-100 bg-img-home">
          <img
            src="../images/logo-byfaz.png"
            alt=""
            className="assessment-two__logo"
          />
          <span className="assessment-two__question-count">
            Question 4 of 4
          </span>
           <div className="pb-wrapper">
            <div className="progressbar">
              <div className="redbar"></div>
              <div className="redbar"></div>
              <div className="redbar"></div>
              <div className="redbar"></div>
            </div>
          </div>
          <span className="form-title">
            What are you interested in learning more about?
          </span>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              {["radio"].map((type) => (
                <div key={type} className="mb-3">
                  <Row>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response4"
                          type={type}
                          onChange={resp4ChangeEventHander}
                          value="Leadership"
                          className="fancy"
                          required
                        />
                        <div>Leadership</div></Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response4"
                          type={type}
                          onChange={resp4ChangeEventHander}
                          value="Teamwork"
                          className="fancy"
                        />
                        <div>Teamwork</div></Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response4"
                          type={type}
                          onChange={resp4ChangeEventHander}
                          value="Problem Solving Skills"
                          className="fancy"
                        />
                          <div>Problem solving skills</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          name="response4"
                          type={type}
                          onChange={resp4ChangeEventHander}
                          value="Growth Potential"
                          className="fancy"
                        />
                        <div>Growth potential</div></Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button type="submit">
                <ReactButton></ReactButton>
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default QuestionFour;
