import "./QuestionOne.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ReactComponent as ReactButton } from "../svg/nextQuestionButton.svg";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

function QuestionOne() {
  const refResponse = useRef(null);
  const [validated, setValidated] = useState(false);
  const history = useHistory();
  
  const isMobile = useMediaQuery({ query: '(min-width: 200px) and (max-width: 767.98px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 767.00px) and (max-width: 991.98px)' });
  const isDesktop = useMediaQuery({ query: '(min-width: 992px)' });

  // Form Data States
  const [enteredResp1, setResp1] = useState("");
  const [enteredQues1, setQues1] = useState("");
  
  const selectedA = [
    "Construction Manager",
    "Framer",
    "Underground Construction",
    "Mason",
    "Electrician",
  ];
  const selectedB = [
    "Construction Manager",
    "Iron Worker",
    "Sprinkler Fitter",
    "Sheet Metal Worker",
    "Pipefitter",
    "Plumber",
    "Welder",
  ];
  const selectedC = [
    "Carpenter",
    "Electrcian",
    "Craft Laborer",
    "Drywall Installer",
    "Framer",
    "Roofer",
    "Pipefitter",
    "Plumber",
    "Sprinkler Fitter",
    "Concrete Finisher",
  ];
  const selectedD = [
    "Construction Manager",
    "Plumber",
    "HVAC Technician",
    "Mason",
    "Sprinkler Fitter",
    "Concrete Finisher",
    "Painter",
    "Underground Construction",
  ];
  const selectedE = [
    "Carpenter",
    "Craft Laborer",
    "Heavy Equipment Operator",
    "Painter",
    "Underground Construction",
  ];
  const selectedF = [
    "Roofer",
    "Framer",
    "Electrcian",
    "Carpenter",
    "Craft Laborer",
    "Mason",
    "Drywall Installer",
    "Millwright",
    "Plumber",
    "Pipefitter",
    "Sheet Metal Worker",
    "Welder",
  ];

  const quesJobs = {
    selectedA,
    selectedB,
    selectedC,
    selectedD,
    selectedE,
    selectedF,
  };

  const resp1ChangeEventHander = (event) => {
    const questionOne = "What is Your Strength?";
    setResp1(event.target.value);
    setQues1(questionOne + ":" + event.target.nextSibling.innerHTML);
  };

  const historyChecked = () => {
    let selectedQues = localStorage.getItem("question_one");
    let escapestring = CSS.escape(selectedQues);

    //.querySelector("input[name=response1][value='" + selectedQues + "']")
    let val1 = document.querySelector('input[name="response1"]');
    console.log(val1);
    //.attributes("checked", "checked");
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();

    if (form.checkValidity() === false) {
      event.stopPropagation();
      setValidated(true);
    }

    if (form.checkValidity() === true) {
      const formData = {
        response1: enteredResp1,
      };

      localStorage.setItem("question_one", JSON.stringify(formData));
      localStorage.setItem("question_one_selected", JSON.stringify(enteredQues1));
      history.push("/question-two");
    }
  };
  return (
    <div
      className="question-one container-fluid h-100">
      <Row lg={12} className="justify-content-center align-items-center">
       {isDesktop &&
        <video autoPlay loop muted id="video" className="d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block">
            <source src="../video/Background_1.mp4" type="video/mp4" />
        </video>
      }
        <Col xs={12} md={12} lg={12} className="bg-img-home">
          <img
            src="../images/logo-byfaz.png"
            alt=""
            className="assessment-two__logo"
          />
          <span className="assessment-two__question-count">
            Question 1 of 4
          </span>
          <div className="pb-wrapper">
            <div className="progressbar">
              <div className="redbar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
              <div className="bar"></div>
            </div>
          </div>
          <span className="form-title">What is Your Strength?</span>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              {["radio"].map((type) => (
                <div key={type} className="options">
                  <Row className="justify-content-center">
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`resp1-${type}`}>
                        <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedA}
                          required
                          className="fancy"
                        />
                        <div>Motivating people</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`resp1-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedB}
                          className="fancy"
                        />
                        
                          <div>Managing the budget and schedules</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                    
                      <Form.Check inline type={type} id={`resp1-${type}`}>
                      <img />
                      <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedC}
                          className="fancy"
                        />
                        
                          <div>Researching and inspiration boards</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`resp1-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedD}
                          className="fancy"
                        />
                        
                          <div>Completing a project quickly</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`resp1-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedE}
                          className="fancy"
                        />
                        
                          <div>Understand measurements and the importance of accuracy</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                    <Col xs={12} md={12} lg={6}>
                      <Form.Check inline type={type} id={`check-api-${type}`}>
                      <Form.Check.Label>
                        <Form.Check.Input
                          type={type}
                          name="response1"
                          onChange={resp1ChangeEventHander}
                          value={selectedF}
                          className="fancy"
                        />
                        
                          <div>Excellent attention to detail</div>
                        </Form.Check.Label>
                      </Form.Check>
                    </Col>
                  </Row>
                </div>
              ))}
              <Button type="submit">
                <ReactButton></ReactButton>
              </Button>
            </Form.Group>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default QuestionOne;
